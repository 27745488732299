import Loadable from 'react-loadable';
import Loader from '../src/utility/Loader';

/**
 * List of routes 
 */
export const ROUTE = [{
    path: '/',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/Products'),
        loading: Loader,
    }),
},
{
    path: '/dashboard',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/Dashboard'),
        loading: Loader,
    }),
},
{
    path: '/products',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/Products'),
        loading: Loader,
    }),
},
{
    path: '/products/add',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/Products/add'),
        loading: Loader,
    }),
},
{
    path: '/products/edit/:id',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/Products/edit'),
        loading: Loader,
    }),
},
{
    path: '/attributes',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/Attributes'),
        loading: Loader,
    }),
},
{
    path: '/models',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/Models'),
        loading: Loader,
    }),
},
{
    path: '/3Dproducts',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/3DProducts'),
        loading: Loader,
    }),
},
{
    path: '/model/add',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/Models/add'),
        loading: Loader,
    }),
},
{
    path: '/3Dproducts/add',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/3DProducts/add'),
        loading: Loader,
    }),
},
{
    path: '/3Dproducts/productDetails/:id',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/3DProducts/productDetails'),
        loading: Loader,
    }),
},
{
    path: '/models/edit/:id',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/Models/edit'),
        loading: Loader,
    }),
},
{
    path: '/3Dproducts/edit/:id',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/3DProducts/edit'),
        loading: Loader,
    }),
},
{
    path: '/settings/fontfamily',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/Fontfamily'),
        loading: Loader,
    }),
},
{
    path: '/settings/fontcolor',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/Fontcolor'),
        loading: Loader,
    }),
},
{
    path: '/settings/fontsize',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/Fontsize'),
        loading: Loader,
    }),
},
{
    path: '/settings/clipart',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/Clipartsettings'),
        loading: Loader,
    }),
},
{
    path: '/settings/clipart/edit/:id',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/Clipartsettings/edit'),
        loading: Loader,
    }),
},
{
    path: '/settings/clipart/add',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/Clipartsettings/add'),
        loading: Loader,
    }),
},
{
    path: '/settings/generalsettings',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/Generalsettings'),
        loading: Loader,
    }),
},
{
    path: '/settings/printing',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/Printing'),
        loading: Loader,
    }),
},
{
    path: '/attributes/edit/:id',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/Attributes/edit'),
        loading: Loader,
    }),
},
{
    path: '/attributes/add',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/Attributes/add'),
        loading: Loader,
    }),
},
{
    path: '/orders',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/Orders'),
        loading: Loader,
    }),
},
{
    path: '/orders/edit/:id',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/Orders/edit'),
        loading: Loader,
    }),
},
{
    path: '/settings',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/Settings'),
        loading: Loader,
    }),
},
{
    path: '/userguide',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/Userguide'),
        loading: Loader,
    }),
},
{
    path: '/logout',
    exact: true,
    component: Loadable({
        loader: () => import('./containers/Logout'),
        loading: Loader,
    }),
}
]