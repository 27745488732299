import { app } from "../../config/app";
import { TOKEN, LINK_PRODUCT, LINK_PRODUCT_DELETE, LINKED_PRODUCTS, BIGCOMMERCE_PRODUCT, PENDING, FULFILLED, REJECTED, SAVE_DOMAIN_DATA } from "../../utility/constants";
export function getToken(data) {
    return {
        types: [`${TOKEN}_${PENDING}`, `${TOKEN}_${FULFILLED}`, `${TOKEN}_${REJECTED}`],
        method: 'post',
        url: 'getToken',
        data: data,
        payload: { receiveType: 'postData' }
    }
}
export function linkProduct(data) {
    let method = 'POST', url = "createRecord";
    data = {
        ...data,
        modelName: "promize_customizer_products",
        idKey: "promize_customizer_products_id"
    }
    if (data.promize_customizer_products_id) {
        method = 'PUT';
        url = 'updateRecord'
    }
    return {
        types: [`${LINK_PRODUCT}_${PENDING}`, `${LINK_PRODUCT}_${FULFILLED}`, `${LINK_PRODUCT}_${REJECTED}`],
        method: method,
        url: url,
        data: data,
        payload: { receiveType: 'postData' }
    }
}
export function unLinkProduct(data) {
    let method = 'DELETE', url = "deleteRecord";
    data = {
        ...data,
        modelName: "promize_customizer_products",
        idKey: "promize_customizer_products_id"
    }
    return {
        types: [`${LINK_PRODUCT_DELETE}_${PENDING}`, `${LINK_PRODUCT_DELETE}_${FULFILLED}`, `${LINK_PRODUCT_DELETE}_${REJECTED}`],
        method: method,
        url: url,
        data: data,
        payload: { receiveType: 'postData' }
    }
}
export function getLinkedProducts(data) {
    return {
        types: [`${LINKED_PRODUCTS}_${PENDING}`, `${LINKED_PRODUCTS}_${FULFILLED}`, `${LINKED_PRODUCTS}_${REJECTED}`],
        method: 'post',
        url: 'getLinkedProducts',
        data: data,
        payload: { receiveType: 'postData' }
    }
}

export function saveDomainDetails(data) {
    return {
        types: [`${SAVE_DOMAIN_DATA}_${PENDING}`, `${SAVE_DOMAIN_DATA}_${FULFILLED}`, `${SAVE_DOMAIN_DATA}_${REJECTED}`],
        method: 'post',
        url: 'saveDomainDetails',
        service: 'BIGCOMMERCE_API',
        data: data,
        payload: { receiveType: 'postData' }
    }
}


export function getBigcommerceProducts(data) {
    return {
        types: [`${BIGCOMMERCE_PRODUCT}_${PENDING}`, `${BIGCOMMERCE_PRODUCT}_${FULFILLED}`, `${BIGCOMMERCE_PRODUCT}_${REJECTED}`],
        method: 'post',
        url: 'getBigcommerceProducts',
        service: 'BIGCOMMERCE_API',
        data: data,
        payload: { receiveType: 'postData' }
    }
}

export function getShopifyProducts(data) {
    return {
        types: [`${BIGCOMMERCE_PRODUCT}_${PENDING}`, `${BIGCOMMERCE_PRODUCT}_${FULFILLED}`, `${BIGCOMMERCE_PRODUCT}_${REJECTED}`],
        method: 'post',
        url: 'getShopifyProducts',
        service: 'BIGCOMMERCE_API',
        data: data,
        payload: { receiveType: 'postData' }
    }
}

export function getMagentoProducts(data) {
    return {
        types: [`${BIGCOMMERCE_PRODUCT}_${PENDING}`, `${BIGCOMMERCE_PRODUCT}_${FULFILLED}`, `${BIGCOMMERCE_PRODUCT}_${REJECTED}`],
        method: 'post',
        url: 'getMagentoProducts',
        service: 'BIGCOMMERCE_API',
        data: data,
        payload: { receiveType: 'postData' }
    }
}

export function linkShopifyProducts(data) {
    return {
        types: [`${LINK_PRODUCT}_${PENDING}`, `${LINK_PRODUCT}_${FULFILLED}`, `${LINK_PRODUCT}_${REJECTED}`],
        method: 'post',
        url: 'linkShopifyProduct',
        service: 'BIGCOMMERCE_API',
        data: data,
        payload: { receiveType: 'postData' }
    }
}
export function unLinkShopifyProduct(data) {
    return {
        types: [`${LINK_PRODUCT}_${PENDING}`, `${LINK_PRODUCT}_${FULFILLED}`, `${LINK_PRODUCT}_${REJECTED}`],
        method: 'post',
        url: 'unLinkShopifyProduct',
        service: 'BIGCOMMERCE_API',
        data: data,
        payload: { receiveType: 'postData' }
    }
}

export function linkBigcommerceProducts(data) {
    return {
        types: [`${LINK_PRODUCT}_${PENDING}`, `${LINK_PRODUCT}_${FULFILLED}`, `${LINK_PRODUCT}_${REJECTED}`],
        method: 'post',
        url: 'linkBigcommerceProduct',
        service: 'BIGCOMMERCE_API',
        data: data,
        payload: { receiveType: 'postData' }
    }
}
export function unLinkBigcommerceProduct(data) {
    return {
        types: [`${LINK_PRODUCT}_${PENDING}`, `${LINK_PRODUCT}_${FULFILLED}`, `${LINK_PRODUCT}_${REJECTED}`],
        method: 'post',
        url: 'unLinkBigcommerceProduct',
        service: 'BIGCOMMERCE_API',
        data: data,
        payload: { receiveType: 'postData' }
    }
}
export function linkMagentoProducts(data) {
    return {
        types: [`${LINK_PRODUCT}_${PENDING}`, `${LINK_PRODUCT}_${FULFILLED}`, `${LINK_PRODUCT}_${REJECTED}`],
        method: 'post',
        url: 'linkMagentoProduct',
        service: 'BIGCOMMERCE_API',
        data: data,
        payload: { receiveType: 'postData' }
    }
}
export function unLinkMagentoProduct(data) {
    return {
        types: [`${LINK_PRODUCT}_${PENDING}`, `${LINK_PRODUCT}_${FULFILLED}`, `${LINK_PRODUCT}_${REJECTED}`],
        method: 'post',
        url: 'unLinkMagentoProduct',
        service: 'BIGCOMMERCE_API',
        data: data,
        payload: { receiveType: 'postData' }
    }
}
