import React from "react";
import { Route, Redirect } from "react-router-dom";
import Application from '../../containers/Application';
/**
 * If we have a logged-in user, redirect to the home page. Otherwise, display the component.
 */
const PublicRoute = ({ component: Component,meta:meta, path:path, ...rest }) => {
    return (
        <Route {...rest}
            render={
                (props) => {
                    return <Application {...props} component={Component} path={path}/>
                }
            }
        />
    )
};

export default PublicRoute;
