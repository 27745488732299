import React from 'react';

class Input extends React.Component {

	classNameCheck() {
		const validation = this.props.form.validation(this.props.fieldName)
		const message = validation ? this.props.state.inputErrors[this.props.fieldName].message : '';
		const classValidation = `form-group ${validation ? 'feild-wrong' : ''} ${this.props.value && !validation ? 'feild-sucess' : ''}`
		const className = this.props.validation ? classValidation : 'form-group';
		return { 'className': className, 'message': message, 'validation': validation };
	}

	input() {
		const validation = this.props.validation === 'true' ? this.classNameCheck() : '';
		return (<React.Fragment><div className="promize-form-group">
			<label htmlFor={this.props.fieldName} className="promize-form-control-label">{this.props.labelName}{this.props.validation === 'true' && <sup>*</sup>}</label>
			<input className="promize-form-control"
				type={this.props.inputType ? this.props.inputType : "text"}
				id={this.props.fieldName}
				step={this.props.inputStep ? this.props.inputStep : ""}
				data-type={this.props.dataType || "text"}
				data-name={this.props.dataName}
				placeholder={this.props.placeholder?this.props.placeholder:""}
				name={this.props.fieldName}
				onBlur={this.props.onBlur || this.props.form.blurEventListner}
				disabled={this.props.disabled}
				onChange={this.props.onChange || this.props.form.handleFieldsChange}
				value={this.props.value ? this.props.value : '' }
				data-validation-name={this.props.labelName}
				maxLength={this.props.maxLength}
				min = {this.props.minLength? (this.props.minLength || 0) : ''} />
			{this.props.infoContent && <span className="promize-form-info"><i className="fa fa-info-circle"></i>{this.props.infoContent}</span>}
			{validation['validation'] && <span className="promize-form-error">{`${this.props.labelName} ` + validation['message']}</span>}
		</div></React.Fragment>);
	}

	render() {

		return (<React.Fragment>
			{this.input()}
		</React.Fragment>);
	}
}

export default Input;
