import toastr from 'toastr';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import Validator from './Validator';

class Form {
  static getInstance(currentComponent, rules, afterSubmit, afterFileSelect, fileRules) {
    return new Form(currentComponent, rules, afterSubmit, afterFileSelect, fileRules);
  }

  constructor(currentComponent, rules, afterSubmit, afterFileSelect, fileRules) {
    this.currentComponent = currentComponent;
    this.validator = { ...Validator };
    this.validator.setComponent(currentComponent).setRules(rules);
    this.rules = rules;
    this.handleFieldsChange = this.handleFieldsChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleIntegerChange = this.handleIntegerChange.bind(this);
    this.blurEventListner = this.blurEventListner.bind(this);
    this.addInputLabelClass = this.addInputLabelClass.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleProductSubmit = this.handleProductSubmit.bind(this);
    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.afterSubmit = afterSubmit;
    this.afterFileSelect = afterFileSelect;
    this.fileRules = fileRules;
    this.validation = this.validation.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.handleFileData = this.handleFileData.bind(this);
    this.updateFormFieldsState = this.updateFormFieldsState.bind(this);
  }

  /**
   * Handle to update the form rules
   *
   * @param object
   * @return void
   */
  getValidationRules() {
    return this.rules;
  }

  /**
   * Handle to update the form rules
   *
   * @param object
   * @return void
   */
  updateRules(rules) {
    if (_.isObject(rules) && !_.isEmpty(rules)) {
      this.rules = rules;
    }
  }

  /**
   * Handle to update the form input fields state
   *
   * @param mixed | name
   * @param mixed | value
   */
  updateFormFieldsState(name, value) {
    if (this.currentComponent && name) {
      let fields = JSON.parse(JSON.stringify(this.currentComponent.state.fields));
      fields = _.set(fields, name, value);
      this.currentComponent.setState({ fields });
    }
  }

  /**
   * Handle field changes
   *
   * @param object
   * @return void
   */
  handleFieldsChange(e) {
    const name = e.target.dataset.hasOwnProperty('name') ? e.target.dataset.name : e.target.name;
    this.updateFormFieldsState(name, e.target.value);
  }

  /**
  * Handle field changes
  *
  * @param object
  * @return void
  */
  handleCheckboxChange(e) {
    const name = e.target.dataset.hasOwnProperty('name') ? e.target.dataset.name : e.target.name;
    this.updateFormFieldsState(name, e.target.checked);
  }

  /**
   * update state if value is number, otherwise nothing would happen
   * @param {*} e
   */
  handleIntegerChange(e) {
    if (this.currentComponent && !isNaN(e.target.value)) {
      this.updateFormFieldsState(e.target.name, e.target.value);
    }
  }


  /**
   * Handle field changes from Plugin
   *
   * @param name
   * @param value
   * @return void
   */
  handleFieldsChangeByPlugin(name, value) {
    if (this.currentComponent && name) {
      let fields = JSON.parse(JSON.stringify(this.currentComponent.state.fields));
      fields = _.set(fields, name, value);
      this.currentComponent.setState({ fields });
    }
  }

  /**
   * Get file rule by element name handleed
   *
   * @param name
   * @return mixed
   */
  getFileRuleByElementName(name) {
    return (
      name
      && this.fileRules.hasOwnProperty(name)
    ) ? this.fileRules[name] : false;
  }

  /**
  * Handle file select
  *
  * @param object
  * @return void
  */
  handleFile(e) {
    e.preventDefault();

    const fileList = e.target.files || e.dataTransfer.files;
    const file = fileList[0];

    if (file && file.name) {
      let fields = JSON.parse(JSON.stringify(this.currentComponent.state.fields));
      const { files } = this.currentComponent.state;
      fields = _.set(fields, e.target.name, file.name);
      files[e.target.name] = file;
      this.currentComponent.setState({ fields, files });
    }
  }

  /**
  * Handle file select
  *
  * @param object
  * @return void
  */
  handleFileData(e) {
    e.preventDefault();
    const fileList = e.target.files || e.dataTransfer.files;
    const file = fileList[0];
    let state = JSON.parse(JSON.stringify(this.currentComponent.state));
    let fields = JSON.parse(JSON.stringify(this.currentComponent.state.fields));
    fields = _.set(fields, e.target.name, file.name);
    state = _.set(state, 'tempFile', file);
    state.fields = fields;
    this.currentComponent.setState(state);
  }

  /**
  * Process the selected file
  *
  * @param object file
  * @param object rules
  * @return void
  */
  processFiles(file, rules) {
    const errors = this.validateFile(file, rules);

    if (errors.length === 0) {
      if (this.isImage(file)) {
        this.loadImage(file);
      }

      if (this.afterFileSelect) {
        return true;
      }
    } else {
      toastr.error(errors.shift(errors), '', { timeOut: 3000 });
    }
  }

  /**
   * Handle file select
   *
   * @param object
   * @return void
   */
  handleFileSelect(e) {
    e.preventDefault();
    const fileList = e.target.files || e.dataTransfer.files;
    const inputErrors = { ...this.currentComponent.state.inputErrors };

    if (fileList && fileList.length > 0) {
      // if (inputErrors[e.target.name] !== undefined) {
      //   inputErrors[e.target.name]['has'] = false;
      //   this.currentComponent.setState({ inputErrors: inputErrors });
      // }
      const file = fileList[0];
      file.fileName = e.target.name;
      // this.handleFieldsChangeByPlugin(e.target.name, file.name);
      return this.processFile(file, this.getFileRuleByElementName(e.target.name));
     
    }
  }

  /**
 * Handle file select
 *
 * @param object
 * @return void
 */
  handleFileDrop(dataTransfer, name) {
    const fileList = dataTransfer.files;
    const inputErrors = { ...this.currentComponent.state.inputErrors };

    if (fileList && fileList.length > 0) {
      if (inputErrors[name] !== undefined) {
        inputErrors[name].has = false;
        this.currentComponent.setState({ inputErrors });
      }
      const file = fileList[0];
      // this.handleFieldsChangeByPlugin(name, file.name);
      this.processFile(file, this.getFileRuleByElementName(name));
    }
  }

  /**
   * Clean the file dom
   * @return void
   */
  cleanFileElement(file) {
    try {
      file.value = null;
    } catch (ex) { }

    if (file.value) {
      file.parentNode.replaceChild(file.cloneNode(true), file);
    }
  }

  /**
   * Process the selected file
   *
   * @param object file
   * @param object rules
   * @return void
   */
  processFile(file, rules) {
    // const errors = this.validateFile(file, rules);
    let errors =[];
    if(file.fileName == "font_file"){
      errors = this.validateFile(file, rules);
    }else{
      errors = this.validateImageFile(file, rules);
    }

    if (errors.length === 0) {
      if (this.isImage(file)) {
        this.loadImage(file);
      } else {
        this.loadFile(file);
      }
      if (this.afterFileSelect) {
        return true;
      }
    } else {
      toastr.error(errors.shift(errors), '', { timeOut: 3000 });
    }
  }

  /**
   * Validate the image file selected
   *
   * @param object file
   * @return object
   */
  validateImageFile(file, rules) {
    const errors = [];

    /**
     * Valid file size selected
     * @rule fileSize
     */
    if (rules.fileSize && file.size > rules.fileSize) {
      errors.push(`File size must be less than ${(rules.fileSize / (1000 * 1000))} MB`);
    }

    /**
     * Valid selected mime type
     * @rule mime
     */
    /*if (rules.mime && rules.mime.indexOf(file.type.replace(/image\//g, '')) === -1) {
      errors.push(`Invalid file uploaded ,Please make sure you select a file with ${rules.mime.join(',')}`);
    }*/
    if(file.type.length > 0 && rules.mime.length > 0){
      if(!rules.mime.includes(file.type.replace(/image\//g, '').toLowerCase()) && !rules.mime.includes(file.type.replace(/application\//g, '').toLowerCase())){
        errors.push(`Invalid file uploaded ,Please make sure you select a file with ${rules.mime.join(',')}`);
      }
    }

    return errors;
  }

  /**
   * Validate the file selected
   *
   * @param object file
   * @return object
   */
  validateFile(file, rules) {
    const errors = [];
    /**
     * Valid file size selected
     * @rule fileSize
     */
    if (rules.fileSize && file.size > rules.fileSize) {
      errors.push(`File size should not be greater than ${(rules.fileSize / (1024 * 1024))} MB`);
    }
    /**
     * Valid selected mime type
     * @rule mime
     */
    /*if (rules.mime && file.type.length > 0 && rules.mime.indexOf(file.type.substring(file.type.indexOf('/') + 1)) === -1) {
      errors.push(`Invalid file uploaded ,Please make sure you select a file with ${rules.mime.join(',')} extension`);
    }
    if (errors.length > 0) {
      this.handleFieldsChangeByPlugin(file.fileName, '');
      this.cleanFileElement(file);
    }*/
    if(file.type.length > 0 && rules.mime.length > 0){
      let get_ext = file.type.substring(file.type.indexOf('/')+1).toLowerCase();
      if(!rules.mime.includes(get_ext)){
        errors.push(`Invalid file uploaded ,Please make sure you select a file with ${rules.mime.join(',')}`);
      }
    }
      if (errors && errors.length > 0) {
        this.handleFieldsChangeByPlugin(file.fileName, '');
        this.cleanFileElement(file);
      }
    
    return errors;
  }

  /**
   * Load selected image file with fileReader
   *
   * @param object file
   * @return void
   */
  loadImage(file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      file.src = e.target.result;
      if (this.afterFileSelect) {
        this.afterFileSelect(file);
      }
    };

    reader.readAsDataURL(file);
  }

  loadFile(file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      file.src = e.target.result;
      if (this.afterFileSelect) {
        this.afterFileSelect(file);
      }
    };

    reader.readAsDataURL(file);
  }

  addInputLabelClass(e) {
    e.preventDefault();
  }

  blurEventListner(e) {
    this.validator.setComponent(this.currentComponent).setRules(this.rules).blurEventListner(e);
  }

  /**
   * Handle form submit
   *
   * @param object
   * @return void
   */
  handleSubmit(e) {
    e.preventDefault();
    if (this.validator.setComponent(this.currentComponent).setRules(this.rules).validateReactForm(e.target) && this.afterSubmit) {
      const { fields } = this.currentComponent.state;
      const status = (fields.status == true) ? 1 : 0;
      const newObj = { ...fields, status };
      if(newObj.hasOwnProperty('font_file')){
        newObj.font_file ?  this.currentComponent.setState({ fields}, () => {
          this.afterSubmit(newObj);
        }) : this.currentComponent.setState({ inputErrors: {'font_file' : 'Required'} });
      }else{
      newObj.hasOwnProperty('image_file') && delete newObj.image_file;
      this.currentComponent.setState({ fields }, () => {
        this.afterSubmit(newObj);
      });
      }
    }
  }

  /**
   * Handle form submit
   *
   * @param object
   * @return void
   */
  handleProductSubmit(e) {
    e.preventDefault();
    if (this.validator.setComponent(this.currentComponent).setRules(this.rules).validateReactForm(e.target) && this.afterSubmit) {
      if (Object.keys(this.currentComponent.state.fields.model_image).length > 0) {
        const { fields } = this.currentComponent.state;
        const model_image = JSON.stringify(fields.model_image);
        const overlay_details = JSON.stringify(fields.overlay_details);
        const status = (fields.status == true) ? 1 : 0;
        const newObj = {
          ...fields,
          model_image,
          overlay_details,
          status,
        };
        delete newObj.image_file;
        this.currentComponent.setState({ fields }, () => {
          this.afterSubmit(newObj);
        });
      } else if(this.currentComponent.state.fields.product_type == 2){
        const { fields } = this.currentComponent.state;
        const status = (fields.status == true) ? 1 : 0;
        const model_image = JSON.stringify(fields.model_image);
        const newObj = {
          ...fields,
          model_image,
          status,
        };
        this.currentComponent.setState({ fields }, () => {
          this.afterSubmit(newObj);
        });
      } else{
        toastr.error('Please add atleast one view', '', { timeOut: 3000 });
      }
    }
  }

  handleSectionSubmit(e) {
    e.preventDefault();
    if (this.validator.setComponent(this.currentComponent).setRules({ section_name: 'required' }).validateReactForm(e.target) && this.afterSubmit) {
    }
  }

  /**
   * check selected file is a image
   *
   * @param object file
   * @return boolean
   */
  isImage(file) {
    return (
      typeof file.hasOwnProperty('type')
      && ['image/png', 'image/gif', 'image/bmp', 'image/jpg', 'image/jpeg'].indexOf(file.type) > -1
    );
  }


  /**
  * Declare the handle Select Change
  */
  handleSelectChange(selected, value) {
    let fields = JSON.parse(JSON.stringify(this.currentComponent.state.fields));
    const selectedDom = ReactDOM.findDOMNode(selected);
    const name = selectedDom.getAttribute('data-name') ? selectedDom.getAttribute('data-name') : selectedDom.getAttribute('name');
    fields = _.set(fields, name, value);
    this.currentComponent.setState({ fields });
  }

  /**
  * Declare the handle getCurrentComponent
  */
  getCurrentComponent() {
    return this.currentComponent;
  }

  /**
  * Declare the handle reset
  */
  validation(fieldName) {
    if (this.currentComponent.state.inputErrors && this.currentComponent.state.inputErrors[fieldName] && this.currentComponent.state.inputErrors[fieldName].has) {
      return true;
    }
    return false;
  }

  /**
  * Declare the handle reset
  */
  reset(state) {
    if (state && (!state.inputErrors || state.inputErrors)) {
      state = _.set(state, 'inputErrors', {});
    }
    this.currentComponent.setState(state);
  }

  /**
  * Handle field changes from Plugin
  *
  * @param name
  * @param value
  * @return void
  */
  handleFieldsChangeByJson(json) {
    if (this.currentComponent) {
      let fields = JSON.parse(JSON.stringify(this.currentComponent.state.fields));
      for (const result of json) {
        fields = _.set(fields, result.name, result.value);
      }
      this.currentComponent.setState({ fields });
    }
  }
}

export default Form;
