import React, { Component } from 'react';

class Loader extends Component {
   
    render() {
        return  <div className="wrapper">
        <div className="preloader"></div>
    </div>
    }
}

export default Loader;