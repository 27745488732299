import { combineReducers } from 'redux';
import {
    DEFAULT_STATE,
    PENDING,
    FULFILLED,
    REJECTED,
    GRID,
    TOKEN,
    LINK_PRODUCT,
    LINKED_PRODUCTS, SAVE_DOMAIN_DATA,
    BIGCOMMERCE_PRODUCT, SHOPIFY_PRODUCT,
    PRODUCT, PRODUCTS, PRODUCT_STATUS_UPDATE, PRODUCT_DELETE,
    TABS, TAB, TAB_DELETE, TAB_STATUS_UPDATE,
    SUB_TABS, SUB_TAB, SUB_TAB_DELETE,
    TAB_ATTRIBUTE, TAB_ATTRIBUTE_DELETE, TAB_ATTRIBUTE_STATUS_UPDATE,
    TAB_ATTRIBUTE_VALUES, TAB_ATTRIBUTE_VALUES_DELETE, TAB_ATTRIBUTE_VALUES_STATUS_UPDATE,
    TAB_ATTRIBUTE_VALUES_LIST, TAB_ATTRIBUTE_VALUES_LIST_DELETE, TAB_ATTRIBUTE_VALUES_LIST_STATUS_UPDATE,
    OPTIONS, OPTION, OPTION_DELETE, OPTION_STATUS_UPDATE,
    ATTRIBUTES, ATTRIBUTE, ATTRIBUTE_DELETE, ATTRIBUTE_STATUS_UPDATE, ATTRIBUTE_CLIPART_VALUES, ATTRIBUTE_UPLOAD_IMAGE_VALUES,
    ATTRIBUTE_VALUES, ATTRIBUTE_VALUES_DELETE, ATTRIBUTE_VALUES_IMAGES, ATTRIBUTE_VALUES_IMAGES_DELETE, TEXT_VALUES,
    ORDERS, ORDER,
    IMAGE_UPLOAD,
    OVERLAY_IMAGE_UPLOAD,
    TAB_THUMBNAIL_IMAGE,
    SECTION_ICON,
    SECTION,
    SECTIONS,
    MODELS, MODEL, MODEL_DELETE, MODEL_STATUS_UPDATE, LINKED_MODELS,
    CLIPARTS, CLIPART, CLIPART_DELETE, CLIPART_STATUS_UPDATE, CLIPART_VALUES, CLIPART_VALUES_DELETE,
    FONTS, FONT, FONT_DELETE, FONT_STATUS_UPDATE, FILE_UPLOAD,
    FONT_COLORS, FONT_COLOR, FONT_COLOR_DELETE, FONT_COLOR_STATUS_UPDATE,
    FONT_SIZES, FONT_SIZE, FONT_SIZE_DELETE, FONT_SIZE_STATUS_UPDATE, FONT_SIZE_UNIQUE,
    FONT_SHAPES, FONT_SHAPE, FONT_SHAPE_DELETE, FONT_SHAPE_STATUS_UPDATE,
    FONT_ALIGNS, FONT_ALIGN, FONT_ALIGN_DELETE, FONT_ALIGN_STATUS_UPDATE,
    SETTINGS, ATTRIBUTE_SETTINGS,
    DOMAIN_SETTINGS,
    CONDITIONS, CONDITION, CONDITION_DELETE,
    ACTIONS, ACTION, ACTION_DELETE,
    RULES, RULE, RULE_DELETE, DUPLICATE_ATTRIBUTE, PRINTING, DOWNLOAD, PRODUCT_PRINT_SETTINGS, SUB_TAB_OPTION, SUB_TAB_ATTR,PRODUCT_TAB_ATTRIBUTE, READIMPORTDATA, MOVEIMPORTFILES, IMPORTATTRIBUTE,IMPORTATTRIBUTEVALUE, READEXPORTCOUNT, READEXPORTATTRVALUECOUNT, READEXPORTDATA, READEXPORTATTRVALUEDATA, REMOVEEXPORTFILE, READIMPORTTHUMBDATA, MOVEIMPORTTHUMBFILES
} from "../utility/constants";

const createReducer = (reducerName, defaultStateParam) => {
    const defaultState = defaultStateParam || DEFAULT_STATE;
    return (state = defaultState, action) => {
        switch (action.type) {
            case `${reducerName}_${PENDING}`:
            case `${reducerName}_${FULFILLED}`:
            case `${reducerName}_${REJECTED}`:
                return Object.assign({}, action.payload)
            default:
                return state;
        }
    }
}

const rootReducer = combineReducers({
    token: createReducer(TOKEN),
    linkedProducts: createReducer(LINKED_PRODUCTS),
    linkProduct: createReducer(LINK_PRODUCT),
    bigCommerceProduct: createReducer(BIGCOMMERCE_PRODUCT),
    shopifyProduct: createReducer(SHOPIFY_PRODUCT),
    saveDomainData: createReducer(SAVE_DOMAIN_DATA),
    products: createReducer(PRODUCTS),
    product: createReducer(PRODUCT, DEFAULT_STATE),
    section: createReducer(SECTION, DEFAULT_STATE),
    sections: createReducer(SECTIONS, DEFAULT_STATE),
    productStatus: createReducer(PRODUCT_STATUS_UPDATE, DEFAULT_STATE),
    productDelete: createReducer(PRODUCT_DELETE, DEFAULT_STATE),
    tabs: createReducer(TABS),
    tab: createReducer(TAB, DEFAULT_STATE),
    tabStatus: createReducer(TAB_STATUS_UPDATE, DEFAULT_STATE),
    tabDelete: createReducer(TAB_DELETE, DEFAULT_STATE),
    subTabs: createReducer(SUB_TABS),
    subTab: createReducer(SUB_TAB, DEFAULT_STATE),
    subTabDelete: createReducer(SUB_TAB_DELETE, DEFAULT_STATE),
    tabAttribute: createReducer(TAB_ATTRIBUTE, DEFAULT_STATE),
    tabAttributeStatus: createReducer(TAB_ATTRIBUTE_STATUS_UPDATE, DEFAULT_STATE),
    tabAttributeDelete: createReducer(TAB_ATTRIBUTE_DELETE, DEFAULT_STATE),
    tabAttributevalues: createReducer(TAB_ATTRIBUTE_VALUES, DEFAULT_STATE),
    tabAttributevaluesStatus: createReducer(TAB_ATTRIBUTE_VALUES_STATUS_UPDATE, DEFAULT_STATE),
    tabAttributevaluesDelete: createReducer(TAB_ATTRIBUTE_VALUES_DELETE, DEFAULT_STATE),
    tabAttributevalueslist: createReducer(TAB_ATTRIBUTE_VALUES_LIST, DEFAULT_STATE),
    tabAttributevalueslistStatus: createReducer(TAB_ATTRIBUTE_VALUES_LIST_STATUS_UPDATE, DEFAULT_STATE),
    tabAttributevalueslistDelete: createReducer(TAB_ATTRIBUTE_VALUES_LIST_DELETE, DEFAULT_STATE),
    options: createReducer(OPTIONS),
    option: createReducer(OPTION, DEFAULT_STATE),
    optionStatus: createReducer(OPTION_STATUS_UPDATE, DEFAULT_STATE),
    optionDelete: createReducer(OPTION_DELETE, DEFAULT_STATE),
    attributes: createReducer(ATTRIBUTES),
    attribute: createReducer(ATTRIBUTE, DEFAULT_STATE),
    attributeStatus: createReducer(ATTRIBUTE_STATUS_UPDATE, DEFAULT_STATE),
    attributeDelete: createReducer(ATTRIBUTE_DELETE, DEFAULT_STATE),
    attributeValues: createReducer(ATTRIBUTE_VALUES),
    attributeUploadImageValues: createReducer(ATTRIBUTE_UPLOAD_IMAGE_VALUES),
    attributeClipartValues: createReducer(ATTRIBUTE_CLIPART_VALUES),
    attributeValuesDelete: createReducer(ATTRIBUTE_VALUES_DELETE, DEFAULT_STATE),
    attributeValuesImages: createReducer(ATTRIBUTE_VALUES_IMAGES, DEFAULT_STATE),
    attributeValuesImagesDelete: createReducer(ATTRIBUTE_VALUES_IMAGES_DELETE, DEFAULT_STATE),
    duplicateAttribute: createReducer(DUPLICATE_ATTRIBUTE),
    textValues: createReducer(TEXT_VALUES),
    cliparts: createReducer(CLIPARTS),
    clipart: createReducer(CLIPART, DEFAULT_STATE),
    clipartStatus: createReducer(CLIPART_STATUS_UPDATE, DEFAULT_STATE),
    clipartDelete: createReducer(CLIPART_DELETE, DEFAULT_STATE),
    clipartValues: createReducer(CLIPART_VALUES),
    clipartValuesDelete: createReducer(CLIPART_VALUES_DELETE, DEFAULT_STATE),
    fonts: createReducer(FONTS),
    font: createReducer(FONT, DEFAULT_STATE),
    fontStatus: createReducer(FONT_STATUS_UPDATE, DEFAULT_STATE),
    fontDelete: createReducer(FONT_DELETE, DEFAULT_STATE),
    fontcolors: createReducer(FONT_COLORS),
    fontcolor: createReducer(FONT_COLOR, DEFAULT_STATE),
    fontColorStatus: createReducer(FONT_COLOR_STATUS_UPDATE, DEFAULT_STATE),
    fontColorDelete: createReducer(FONT_COLOR_DELETE, DEFAULT_STATE),
    fontsizes: createReducer(FONT_SIZES),
    fontsize: createReducer(FONT_SIZE, DEFAULT_STATE),
    fontSizeStatus: createReducer(FONT_SIZE_STATUS_UPDATE, DEFAULT_STATE),
    fontSizeDelete: createReducer(FONT_SIZE_DELETE, DEFAULT_STATE),
    fontSizeUnique: createReducer(FONT_SIZE_UNIQUE, DEFAULT_STATE),

    fontShapes: createReducer(FONT_SHAPES),
    fontShape: createReducer(FONT_SHAPE, DEFAULT_STATE),
    fontShapeStatus: createReducer(FONT_SHAPE_STATUS_UPDATE, DEFAULT_STATE),
    fontShapefontSizeDelete: createReducer(FONT_SHAPE_DELETE, DEFAULT_STATE),

    fontAligns: createReducer(FONT_ALIGNS),
    fontAlign: createReducer(FONT_ALIGN, DEFAULT_STATE),
    fontAlignStatus: createReducer(FONT_ALIGN_STATUS_UPDATE, DEFAULT_STATE),
    fontAlignDelete: createReducer(FONT_ALIGN_DELETE, DEFAULT_STATE),

    fileUpload: createReducer(FILE_UPLOAD, DEFAULT_STATE),
    orders: createReducer(ORDERS),
    order: createReducer(ORDER, DEFAULT_STATE),
    grid: createReducer(GRID),
    imageUpload: createReducer(IMAGE_UPLOAD, DEFAULT_STATE),
    overlayImageUpload: createReducer(OVERLAY_IMAGE_UPLOAD, DEFAULT_STATE),
    tabThumbnailImage: createReducer(TAB_THUMBNAIL_IMAGE, DEFAULT_STATE),
    sectionIcon: createReducer(SECTION_ICON, DEFAULT_STATE),
    settings: createReducer(SETTINGS),
    attributesettings: createReducer(ATTRIBUTE_SETTINGS),
    conditions: createReducer(CONDITIONS),
    domain_settings: createReducer(DOMAIN_SETTINGS),
    product_print_settings: createReducer(PRODUCT_PRINT_SETTINGS),
    condition: createReducer(CONDITION, DEFAULT_STATE),
    condtionDelete: createReducer(CONDITION_DELETE, DEFAULT_STATE),
    actions: createReducer(ACTIONS),
    action: createReducer(ACTION, DEFAULT_STATE),
    actionDelete: createReducer(ACTION_DELETE, DEFAULT_STATE),
    rules: createReducer(RULES),
    rule: createReducer(RULE),
    ruleDelete: createReducer(RULE_DELETE, DEFAULT_STATE),
    models: createReducer(MODELS),
    model: createReducer(MODEL, DEFAULT_STATE),
    linkedModels: createReducer(LINKED_MODELS),
    modelStatus: createReducer(MODEL_STATUS_UPDATE, DEFAULT_STATE),
    modelDelete: createReducer(MODEL_DELETE, DEFAULT_STATE),
    printing: createReducer(PRINTING),
    download: createReducer(DOWNLOAD),
    sub_tab_option: createReducer(SUB_TAB_OPTION),
    sub_tab_attr: createReducer(SUB_TAB_ATTR),
    product_tab_attribute: createReducer(PRODUCT_TAB_ATTRIBUTE),
    readimportdata: createReducer(READIMPORTDATA),
    readimportthumbdata:createReducer(READIMPORTTHUMBDATA),
    moveimportfiles:createReducer(MOVEIMPORTFILES),
    importattribute:createReducer(IMPORTATTRIBUTE),
    importattributevalue:createReducer(IMPORTATTRIBUTEVALUE),
    readexportdata:createReducer(READEXPORTDATA),
    readexportcount:createReducer(READEXPORTCOUNT),
    readexportattrvaluecount: createReducer(READEXPORTATTRVALUECOUNT),
    readexportattrvaluedata:createReducer(READEXPORTATTRVALUEDATA),
    removeexportfile: createReducer(REMOVEEXPORTFILE),
    moveimportthumbfiles: createReducer(MOVEIMPORTTHUMBFILES)
})

export default rootReducer
