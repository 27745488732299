import $ from 'jquery';
import { isEmpty, isUndefined } from 'lodash';

const validator = {
  rules: {},
  errors: {},
  params: {},
  forms: {},
  currentModule: false,
  localeLoaded: false,
  component: false,
  through: 'angular',
  asyncErrors: {},
  angularPromise: false,
  RADIO: 'radio',
  currentForm: undefined,
  blurEventListner(event) {
    let element;
    if (event.target) {
      element = event.target;
    } else {
      element = event;
    }
    const currentValidator = this.component;
    const self = this;
    const initValidator = () => {
      let errors = self.validateElement(element, null);
      if (currentValidator && currentValidator.hasOwnProperty('state')) {
        errors = self.getFormattedErrorMessage(errors, currentValidator.state.inputErrors ? currentValidator.state.inputErrors : {}, element);
        currentValidator.setState({ inputErrors: errors });
      }
    };

    if (element.dataset.hasOwnProperty('datepicker')) {
      setTimeout(initValidator, 200);
    } else {
      initValidator();
    }
  },
  setComponent(component) {
    this.component = component;
    return this;
  },
  reintializeValidation(form, scope, module) {
    if (module && !scope.errors[module]) {
      scope.errors[module] = {};
    }

    if (module && module.trim().length > 0) {
      validator.setCurrentModule(module).setFormByModule(form);
    }

    form.find('textarea,select,input:not(input[type="hidden"],input[type="submit"],input[type="file"],input[data-ignore-validation])').each(function () {
      $(this).on('blur', function () {
        const element = this;

        const initValidator = function () {
          const errors = validator.validateElement(element, module);

          if (scope.hasOwnProperty('errors')) {
            if (module && module.trim().length > 0) {
              scope.errors[module] = validator.getFormattedErrorMessage(errors, scope.errors.hasOwnProperty(module) ? scope.errors[module] : {}, element);
            } else {
              scope.errors = validator.getFormattedErrorMessage(errors, scope.errors, element);
            }

            if (!scope.$$phase) {
              scope.$apply();
            }
          }
        };

        if (element.dataset.hasOwnProperty('datepicker')) {
          setTimeout(initValidator, 200);
        } else {
          initValidator();
        }
      });
    });
  },
  validateReactForm(form, module) {
    const self = this;
    this.currentForm = form;
    let currentValidator = this.component;
    if (!currentValidator || !currentValidator.state) {
      currentValidator = { state: { inputErrors: {} } };
    }
    if (module && !currentValidator.state.inputErrors[module]) {
      currentValidator.state.inputErrors[module] = {};
    }
    $(form).find('textarea,select,input:not(input[type="submit"],input[type="file"],input[data-ignore-validation],input[data-unique])').each(function () {
      const validationErrors = self.validateElement(this, module);

      if (module) {
        Object.assign(
          currentValidator.state.inputErrors[module],
          self.getFormattedErrorMessage(validationErrors, currentValidator.state.inputErrors[module], this),
        );
      } else {
        Object.assign(currentValidator.state.inputErrors, validator.getFormattedErrorMessage(validationErrors, currentValidator.state.inputErrors, this));
      }
    });

    currentValidator.setState({ inputErrors: currentValidator.state.inputErrors });
    if (Object.keys(currentValidator.state.inputErrors)[0] && form.querySelector(`[name="${Object.keys(currentValidator.state.inputErrors)[0]}"]`)) {
      form.querySelector(`[name="${Object.keys(currentValidator.state.inputErrors)[0]}"]`).focus();
    }
    return Object.keys(typeof module === 'string' ? currentValidator.state.inputErrors[module] : currentValidator.state.inputErrors).length === 0;
  },

  getFormattedErrorMessage(validationErrors, errors, element) {
    if (!isUndefined(errors)) {
      const index = element.dataset.hasOwnProperty('index') ? element.dataset.index : false;
      const parent = element.dataset.hasOwnProperty('parent') ? element.dataset.parent : false;
      if (Object.keys(validationErrors).length === 0) {
        if (
          index
          && parent
          && errors.hasOwnProperty(parent)
          && errors[parent].hasOwnProperty(index)
          && errors[parent][index].hasOwnProperty(element.getAttribute('name'))
        ) {
          delete errors[parent][index][element.getAttribute('name')];

          if (Object.is({}, errors[parent][index])) {
            delete errors[parent][index];
          }

          if (Object.is({}, errors[parent])) {
            delete errors[parent];
          }
        } else if (errors.hasOwnProperty(element.getAttribute('name'))) {
          delete errors[element.getAttribute('name')];
        }
        return errors;
      }

      Object.keys(validationErrors).forEach((field) => {
        const msg = validationErrors[field];

        if (msg) {
          if (index && parent) {
            if (!errors.hasOwnProperty(parent)) {
              errors[parent] = {};
            }

            if (!errors[parent].hasOwnProperty(index)) {
              errors[parent][index] = {};
            }

            errors[parent][index][field] = { has: true, message: msg };
          } else {
            errors[field] = { has: true, message: msg };
          }
        } else if (errors.hasOwnProperty(element.getAttribute('name'))) {
          delete errors[element.getAttribute('name')];
        }
      });
    }
    return errors;
  },
  fillElementErrorMessageUsingJquery(element, errors) {
    const eleName = element.getAttribute('name');
    errors = (typeof errors === 'object') ? errors : this.errors;

    if (errors.hasOwnProperty(eleName) && errors[eleName]) {
      $(element).parent().addClass('has-error').find('.help-block')
        .removeClass('hide')
        .text(errors[eleName]);
    }
  },
  fillMultiCheckErrorMessageUsingJquery(element, errors, elementName) {
    errors = (typeof errors === 'object') ? errors : this.errors;

    if (errors.hasOwnProperty(elementName) && errors[elementName]) {
      $(element).closest('div.form-group').addClass('has-error').find('.help-block')
        .removeClass('hide')
        .text(errors[elementName]);
    }
  },
  cleanElementErrorMessage(element) {
    $(element).parent().removeClass('has-error').find('.help-block')
      .addClass('hide')
      .text('');

    return this;
  },
  cleanMultiCheckElementErrorMessage(element) {
    $(element).closest('div.form-group').removeClass('has-error').find('.help-block')
      .addClass('hide')
      .text('');

    return this;
  },
  reintializeEventValidationByModule(module, scope) {
    if (this.forms.hasOwnProperty(module) && typeof this.forms[module] === 'object') {
      this.forms[module].find('textarea,select,input:not(input[type="hidden"],input[data-datepicker],input[type="submit"],input[type="file"],input[data-ignore-validation])').each(function () {
        $(this).on('blur', function () {
          const errors = validator.validateElement(this, module);

          if (scope.hasOwnProperty('errors')) {
            scope.errors[module] = validator.getFormattedErrorMessage(errors, scope.errors.hasOwnProperty(module) ? scope.errors[module] : {}, this);

            if (!scope.$$phase) {
              scope.$apply();
            }
          }
        });
      });
    }

    return this;
  },
  validateElement(element, module) {
    if (!element.hasAttribute('data-ignore-validation')) {
      const elementRule = this.getRuleByElement(element, module);

      if (elementRule) {
        return this.validate(element, elementRule);
      }
    }

    return {};
  },
  validate(element, elementRule) {
    const rules = elementRule.split('|');
    if (this.through !== 'jQuery') {
      this.errors = {};
    }
    this.errors = {};
    for (let iter = 0; iter < rules.length; iter++) {
      const validatorMethod = this.getValidatorName(rules[iter]);
      this.setParamByRule(rules[iter],element);

      if (this.hasOwnProperty(validatorMethod) && !this[validatorMethod](element)) {
        if (validatorMethod !== 'validateUnique') {
          this.setErrorMessageForElement(this.getParseRuleName(rules[iter]), element);
        }
        break;
      }
    }
    return this.errors;
  },
  setRules(rules, module) {
    if (typeof rules === 'object' && !isEmpty(rules) && rules !== null && typeof module === 'string') {
      this.rules[module] = rules;
    } else if (typeof rules === 'object' && !isEmpty(rules) && rules != null) {
      this.rules = rules;
    } else {
      this.rules = {};
    }

    return this;
  },

  setScope(scope) {
    this.scope = scope;

    return this;
  },
  setCurrentModule(module) {
    this.currentModule = module;

    return this;
  },
  setFormByModule(form) {
    if (this.currentModule) {
      this.forms[this.currentModule] = form;
    }

    return this;
  },
  setAngularPromise(promise) {
    this.angularPromise = promise;

    return this;
  },
  setTinyMceElementValidation(element) {
    element.on('blur', (e) => {
      if (typeof element.targetElm === 'object') {
        element.targetElm.innerHTML = element.getContent();
        validator.cleanElementErrorMessage(element.targetElm).validateElement(element.targetElm);
        validator.fillElementErrorMessageUsingJquery(element.targetElm, false);
      }
    });
  },
  getRuleByElement(element, module) {
    const eleName = (typeof element === 'string') ? element : element.getAttribute('name');
    const rulesByModule = (typeof module === 'string' && this.rules.hasOwnProperty(module)) ? this.rules[module] : this.rules;

    return rulesByModule.hasOwnProperty(eleName) ? rulesByModule[eleName] : false;
  },
  ucfirst(str) {
    str += '';
    const f = str.charAt(0).toUpperCase();
    return f + str.substr(1);
  },
  getErrorMessageByRule(rule) {
    let message = false;

    try {
      message = (rule) || false;
    } catch (error) {
      return error;
    }

    return message;
  },
  getValidatorName(rule) {
    let validatorMethod = `validate${this.ucfirst(this.camelCase(rule))}`;

    if (validatorMethod.indexOf(':') !== -1) {
      validatorMethod = validatorMethod.substr(0, validatorMethod.indexOf(':'));
    }

    return validatorMethod;
  },
  camelCase(str) {
    return str.replace(/^([A-Z])|[\s-_](\w)/g, (match, p1, p2, offset) => ((p2) ? p2.toUpperCase() : p1.toLowerCase()));
  },
  getParseRuleName(ruleName) {
    if (ruleName.indexOf(':') !== -1) {
      ruleName = ruleName.substr(0, ruleName.indexOf(':'));
    }

    return ruleName;
  },
  setParamByRule(rule,element) {
    const params = this.getParamFromRule(rule);
    const ruleName = this.getParseRuleName(rule);
    const elename = element.getAttribute('name');
    if (typeof params === 'object' && params.length > 0) {
      switch (ruleName) {
        case 'max':
          var maxLength = parseInt(params[0], 10);

          if (typeof maxLength === 'number') {
            this.params[ruleName] = maxLength;
          }
          break;
        case 'min':
          var minLength = parseInt(params[0], 10);

          if (typeof minLength === 'number') {
            this.params[ruleName] = minLength;
          }
          break;
        case 'required_unless':
        case 'required_if':
          if (params.length > 1) {
            this.params[ruleName] = { inputName: params[0], inputValue: params[1] };
          }
          break;
        case 'url':
          this.params[ruleName] = { inputName: params[0] };
          break;
        case 'required_with':
          this.params[ruleName] = { inputName: params[0] };
          break;
        case 'same':
        case 'greater_than':
          // this.params[ruleName] = { inputName: params[0] };
          this.params[ruleName] = { inputName: elename , inputValue:params[0] };
          break;
        case 'different':
          this.params[ruleName] = { inputName: params[0] };
          break;
        default:
          break;
      }
    }

    return ruleName;
  },
  getParamFromRule(rule) {
    let params = [];

    if (rule.indexOf(':') !== -1) {
      params = rule.substr(rule.indexOf(':') + 1).split(',');
    }

    return params;
  },
  formatName(name) {
    if (name && name.indexOf('_id') !== -1) {
      name = name.substr(0, name.indexOf('_id'));
    } else if (name) {
      name = this.camelCase(name);
    }

    return name;
  },
  /**
   * get dom element object by name
   * if index is set get the element by id
   *
   * @param string name
   * @param string index
   * @return object
   */
  getElement(name, index) {
    if (typeof index !== 'undefined') {
      return document.getElementById(`${name}_${index}`);
    }

    const elements = document.getElementsByName(name);
    let matchedElement = false;

    if (elements.length > 0) {
      /**
       * if the element is radio will return the selected radio element
       */
      if (elements[0].getAttribute('type') === this.RADIO) {
        for (let i = 0; i < elements.length; i++) {
          if (elements[i].checked) {
            matchedElement = elements[i];
            break;
          }
        }
      } else {
        matchedElement = elements[0];
      }
    }

    return matchedElement;
  },
  setErrorMessageForElement(rule, element, params) {
    const eleName = element.getAttribute('name');
    const displayName = element.getAttribute('data-validation-name') ? element.getAttribute('data-validation-name') : this.formatName(element.getAttribute('name').replace(/[0-9]/g, ''));
    let message = this.getErrorMessageByRule(rule);
    if (element.hasAttribute('data-validation-message') && !isEmpty(element.getAttribute('data-validation-message'))) {
      message = element.getAttribute('data-validation-message');
    } else if (message) {
      switch (rule) {
        case 'max':
          if (typeof message === 'object' && (element.getAttribute('type') === 'number' || element.getAttribute('data-type') === 'number') && message.hasOwnProperty('numeric')) {
            message = message.numeric.replace(/:attribute/g, this.ucfirst(displayName))
              .replace(/:max/g, this.params.hasOwnProperty('max') ? this.params.max : '');
          } else if (typeof message === 'object' && message.hasOwnProperty('string')) {
            message = message.string.replace(/:attribute/g, this.ucfirst(displayName))
              .replace(/:max/g, this.params.hasOwnProperty('max') ? this.params.max : '');
          }
          break;
        case 'min':
          if (typeof message === 'object' && (element.getAttribute('type') === 'number' || element.getAttribute('data-type') === 'number') && message.hasOwnProperty('numeric')) {
            message = message.numeric.replace(/:attribute/g, this.ucfirst(displayName))
              .replace(/:min/g, this.params.hasOwnProperty('min') ? this.params.min : '');
          } else if (typeof message === 'object' && message.hasOwnProperty('string')) {
            message = message.string.replace(/:attribute/g, this.ucfirst(displayName))
              .replace(/:min/g, this.params.hasOwnProperty('min') ? this.params.min : '');
          }
          break;
        case 'required_unless':
          message = message.replace(/:attribute/g, this.ucfirst(displayName))
            .replace(/:other/g, this.ucfirst(this.formatName(this.params.required_unless.inputName)))
            .replace(/:value/g, this.params.required_unless.hasOwnProperty('label')
              ? this.params.required_unless.label : '');
          break;
        case 'required_if':
          message = message.replace(/:attribute/g, this.ucfirst(displayName))
            .replace(/:other/g, this.ucfirst(this.formatName(this.params.required_if.inputName)))
            .replace(/:value/g, this.params.required_if.hasOwnProperty('label')
              ? this.params.required_if.label : '');
          break;
        case 'required_with':
          message = message.replace(/:attribute/g, this.ucfirst(displayName))
            .replace(/:values/g, this.ucfirst(this.formatName(this.params.required_with.inputName)))
            .replace(/:value/g, this.params.required_with.hasOwnProperty('label'));
          break;
        case 'same':
          if (this.params.same.inputName === 'userpassword') {
            message = message.replace(/:attribute/g, this.ucfirst(displayName))
              .replace(/:other/g, this.ucfirst('password'))
              .replace(/:value/g, this.params.same.hasOwnProperty('label'));
          } else {
            message = message.replace(/:other/g, this.ucfirst(displayName))
              .replace(/:attribute/g, (element.form.querySelector(`[name="${this.params.same.inputName}"]`).dataset.validationName) ? element.form.querySelector(`[name="${this.params.same.inputName}"]`).dataset.validationName : this.ucfirst(this.formatName(this.params.same.inputName)))
              .replace(/:value/g, this.params.same.hasOwnProperty('label'));
          }
          break;
        case 'greater_than':
          const dependentEleVal = element.form.querySelector(`[name="${this.params.greater_than.inputName}"]`).value;
          if (dependentEleVal == 0 || !isEmpty(dependentEleVal)) {
            message = message.replace(/:attribute/g, this.ucfirst(displayName))
              .replace(/:value/g, dependentEleVal);
              message = ' must be greater than '+this.params.greater_than.inputValue;
          }
          break;
        case 'different':
          message = message.replace(/:attribute/g, this.ucfirst(displayName))
            .replace(/and :other/g, '')
            .replace(/:value/g, this.params.different.hasOwnProperty('label'));
          break;
        case 'url':
          message = message.replace(/:attribute/g, this.ucfirst(displayName));
          break;
        default:
          message = message.replace(/:attribute/g, this.ucfirst(displayName));
          break;
      }
      this.errors[eleName] = message;
    }

    if (message) {
      switch (displayName) {
        case 'userpassword':
          message = message.replace(/Userpassword/g, this.ucfirst(displayName.substring(4)));
          break;
        default:
          message = message.replace(/:attribute/g, this.ucfirst(displayName));
          break;
      }
      this.errors[eleName] = message;
    }
  },
  getPromise(url) {
    let promise = false;

    if (url) {
      switch (this.through) {
        case 'jQuery':
          promise = $.ajax({ url });
          break;
        default:
          promise = this.angularPromise ? this.angularPromise.get(url) : false;
          break;
      }
    }

    return promise;
  },
  promiseFailHandler(response, element) {
    const eleName = element.getAttribute('name');
    this.asyncErrors[eleName] = this.getErrorMessageByRule('unique').replace(/:attribute/g, this.ucfirst(eleName));
    this.fillElementErrorMessageUsingJquery(element, this.asyncErrors);
  },
  promiseSuccessHandler(response, element) {
    const eleName = element.getAttribute('name');

    if (this.asyncErrors.hasOwnProperty(eleName)) {
      delete this.asyncErrors[eleName];
    }
  },
  validateRequired(element) {
    // Check the radio or checkbox element
    if (element.type == 'radio' || element.type == 'checkbox') {
      const elementObj = document.querySelector(`input[name="${element.name}"]:checked`);
      return (elementObj && elementObj.value.trim().length > 0);
    }
    return (element.value && element.value.trim().length > 0);
  },
  validateAlpha(element) {
    return /^[A-Za-z ]+$/.test(element.value);
  },
  validateEmail(element) {
    return (element.value && element.value.trim()) ? /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(element.value) : true;
  },
  validateLandline(element) {
    return (element.value && element.value.trim()) ? /^(\d+-?)+\d+$/.test(element.value) : true;
  },
  validateNumeric(element) {
    return (element.value && element.value.trim()) ? /^-?\d*(\.\d+)?$/.test(element.value) : true;
  },
  validateNumericMin(element) {
    if (element.value && element.value.trim()) {
      return /^-?\d*(\.\d+)?$/.test(element.value) && element.value > 0;
    }
    return true;
  },
  validateMax(element) {
    if (element.value && this.params.hasOwnProperty('max')) {
      return (element.getAttribute('type') === 'number') ? Number(element.value) <= this.params.max : element.value.trim().length <= this.params.max;
    }
    return true;
  },
  validateMin(element) {
    if (element.value && this.params.hasOwnProperty('min')) {
      return element.value.trim().length >= this.params.min;
    }
    return true;
  },
  validateUrl(element) {
    if (element.value) {
      return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(element.value);
    }
    return true;
  },
  validateSame(element) {
    if (
      this.params.hasOwnProperty('same')
      && typeof this.params.same === 'object'
      && this.params.same.hasOwnProperty('inputName')
    ) {
      const dependendElement = this.component.state.fields[this.params.same.inputName];
      if (element.value !== dependendElement) {
        return false;
      }
    }
    return true;
  },
  validateGreaterThan(element) {
    if (
      this.params.hasOwnProperty('greater_than')
      && typeof this.params.greater_than === 'object'
      && this.params.greater_than.hasOwnProperty('inputName')
    ) {
      // const dependendElement = this.component.state.fields[this.params.greater_than.inputName];
      const dependendElement = this.params.greater_than.inputValue;
      if (parseInt(element.value) <= parseInt(dependendElement)) {
        return false;
      }
    }
    return true;
  },
  validateDifferent(element) {
    const dependendElement = this.getElement(this.params.different.inputName);
    if (element.value === dependendElement.value) {
      return false;
    }
    return true;
  },
  validateRegex(element) {
    const str = element.value;
    if (str.indexOf(' ') !== -1) {
      return false;
    } if (/^[a-zA-Z0-9]*$/.test(str) === false) {
      return false;
    }
    return true;
  },
  validateSecurePassword(element) {
    const str = element.value;
    if (str.indexOf(' ') !== -1) {
      return false;
    } if (/((?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{2,})/.test(str) === false) {
      return false;
    }
    return true;
  },
  validateRequiredIf(element) {
    if (
      this.params.hasOwnProperty('required_if')
      && typeof this.params.required_if === 'object'
      && this.params.required_if.hasOwnProperty('inputName')
      && this.params.required_if.hasOwnProperty('inputValue')
    ) {
      const dependendElement = this.getElement(this.params.required_if.inputName, element.dataset.hasOwnProperty('index') ? element.dataset.index : false);
      if (
        dependendElement
        && dependendElement.value === this.params.required_if.inputValue
        && !this.validateRequired(element)
      ) {
        if (dependendElement.getAttribute('type') === 'SELECT') {
          /**
           * set the selected value label so we can use it for error message
           */
          this.params.required_if.label = dependendElement.options[dependendElement.selectedIndex].innerHTML;
        } else {
          this.params.required_if.label = '';
        }
        return false;
      }
    }
    return true;
  },
  validateRequiredUnless(element) {
    if (
      this.params.hasOwnProperty('required_unless')
      && typeof this.params.required_unless === 'object'
      && this.params.required_unless.hasOwnProperty('inputName')
      && this.params.required_unless.hasOwnProperty('inputValue')
    ) {
      const dependendElement = this.getElement(this.params.required_unless.inputName);
      const dependendValue = dependendElement && dependendElement.value ? parseInt(dependendElement.value, 10) : false;

      if (
        typeof dependendValue === 'number'
        && parseInt(dependendElement.value, 10) !== parseInt(this.params.required_unless.inputValue, 10)
        && !this.validateRequired(element)
      ) {
        /**
         * set the selected value label so we can use it for error message
         */
        this.params.required_unless.label = dependendElement.options[dependendElement.selectedIndex].innerHTML;
        return false;
      }
    }

    return true;
  },
  validateRequiredWith(element) {
    if (
      this.params.hasOwnProperty('required_with')
      && typeof this.params.required_with === 'object'
    ) {
      const dependendElement = this.getElement(this.params.required_with.inputName, element.dataset.hasOwnProperty('index') ? element.dataset.index : false);

      if (
        dependendElement
        && typeof dependendElement === 'object'
        && dependendElement.value.trim().length > 0
        && !this.validateRequired(element)
      ) {
        return false;
      }
    }

    return true;
  },
  /**
   * validate user entry is date
   * @see http://jsfiddle.net/EywSP/849/
   */
  validateDate(element) {
    return (element.value.trim().length > 0) ? this.isDate(element.value.trim()) : true;
  },
  isValidateDateofBirth(dob) {
    let isValideDate = true;
    if (this.isDate(dob)) {
      const today = new Date();
      const birthDate = new Date(dob);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      if (age <= 18) {
        isValideDate = false;
      } else {
        isValideDate = true;
      }
    } else {
      isValideDate = false;
    }

    return isValideDate;
  },
  isDate(date) {
    let isValideDate = true;
    if (date) {
      const dateArray = date.match(/^(\d{4})(\/|-)(\d{1,2})(\/|-)(\d{1,2})$/);

      if (typeof dateArray === 'object' && dateArray !== null && dateArray.length >= 6) {
        const dateMonth = dateArray[3];
        const dateDay = dateArray[5];
        const dateYear = dateArray[1];

        if (dateMonth < 1 || dateMonth > 12 || dateDay < 1 || dateDay > 31) {
          isValideDate = false;
        } else if ((dateMonth === 4 || dateMonth === 6 || dateMonth === 9 || dateMonth === 11) && dateDay === 31) {
          isValideDate = false;
        } else if (dateMonth === 2 && (dateDay > 29 || (dateDay === 29 && !(dateYear % 4 === 0 && (dateYear % 100 !== 0 || dateYear % 400 === 0))))) {
          isValideDate = false;
        }
      } else {
        isValideDate = false;
      }
    }

    return isValideDate;
  },
  validateUnique(element) {
    if (element.hasAttribute('data-unique')) {
      const xhr = new XMLHttpRequest();
      const eleName = element.getAttribute('name');

      xhr.open('GET', `${element.getAttribute('data-unique')}?q=${element.value}`);

      xhr.addEventListener('load', function () {
        if (this.status !== 200 && validator.scope && validator.scope.hasOwnProperty('errors')) {
          validator.scope.errors[eleName] = {
            has: true,
            message: validator.getErrorMessageByRule('unique').replace(/:attribute/g, validator.ucfirst(eleName)),
          };
        } else {
          delete validator.scope.errors[eleName];
        }

        if (!validator.scope.$phase) {
          validator.scope.$apply();
        }
      }, false);

      xhr.send();
    }

    return true;
  },
  validateMultiCheckbox(checkboxElements) {
    const lastCheckboxElement = checkboxElements[checkboxElements.length - 1];
    let eleName = lastCheckboxElement.getAttribute('data-multicheck-validate');
    const dependendParams = lastCheckboxElement.hasAttribute('data-dependend-checkbox') ? lastCheckboxElement.getAttribute('data-dependend-checkbox') : false;

    if (dependendParams) {
      const params = dependendParams.split(',');
      if (params.length > 1) {
        var inputName = params[0]; var
          inputValue = params[1];
      }
      const dependendElement = this.getElement(inputName);

      if (typeof dependendElement === 'object') {
        const dependendValue = dependendElement.value ? parseInt(dependendElement.value, 10) : false;

        if (
          typeof dependendValue === 'number'
          && parseInt(dependendElement.value, 10) !== parseInt(inputValue, 10)
        ) {
          return true;
        }
      }
    }

    this.cleanMultiCheckElementErrorMessage(lastCheckboxElement);

    let isChecked = false;

    for (let iter = 0; iter < checkboxElements.length; iter++) {
      if (checkboxElements[iter].checked === true) {
        isChecked = true;
        break;
      }
    }
    if (!isChecked) {
      eleName = lastCheckboxElement.getAttribute('data-multicheck-validate');

      this.errors[eleName] = this.getErrorMessageByRule('multlicheck').replace(/:attribute/g, this.ucfirst(eleName));

      this.fillMultiCheckErrorMessageUsingJquery(lastCheckboxElement, false, eleName);
    }
  },
};

export default validator;
