import React from 'react';
import { Route } from "react-router-dom";
// import Header from '../components/layouts/Header';
import SideBar from '../components/layouts/Sidebar';
import { app } from '../config/app';
const Application = ({ component: Component, path:path, ...rest}) => (
    <Route
        {...rest}
        render={
            (props) => {

                return ( 
                    <div className="promize-main-wrapper">
                        <div className="promize-header">

                        </div>
                        <aside className="promize-left-sizebar">
                            {<SideBar  {...props}/>}  
                        </aside>
                        <div className="promize-page-wrapper">
                        <div className="promize-container-fuild">

                            <Component {...props} app={app} />
                        </div>
                        </div>
                    </div>
                );
            }
        }
    />
);

export default Application;