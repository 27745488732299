import React from "react";
import { Route } from "react-router-dom";
import { connect } from 'react-redux';
import Application from '../../containers/Application';
import _ from 'lodash';

/**
 * If we have a logged-in user, display the component, otherwise redirect to login page.
 */
const PrivateRoute = ({ component: Component, dispatch:dispatch, meta:meta, path:path, permission:permission, ...rest }) => (
    <Route
        {...rest}
        render={
            (props) => {
                    return <Application {...props} component={Component} path={path} />
            }
        }
    />
);
export default connect()(PrivateRoute);