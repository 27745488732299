// Default state
export const DEFAULT_STATE = {
    isFetching: true,
    isError: false,
    response: {}
};
export const DEFAULT_STATE_FF_EF = {
    isFetching: false,
    isError: false,
    response: {}
};
export const DEFAULT_STATE_FF_ET = {
    isFetching: false,
    isError: true,
    response: {}
};


export const LOGOUT = 'LOGOUT';

// API action common constants
export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';

export const TOKEN = 'TOKEN';

export const LINK_PRODUCT = 'LINK_PRODUCT';
export const LINK_PRODUCT_DELETE = 'LINK_PRODUCT_DELETE';
export const LINKED_PRODUCTS = 'LINKED_PRODUCTS';

export const SAVE_DOMAIN_DATA = 'SAVE_DOMAIN_DATA';
export const BIGCOMMERCE_PRODUCT = 'BIGCOMMERCE_PRODUCT';
export const SHOPIFY_PRODUCT = 'SHOPIFY_PRODUCT';


export const PRODUCTS = 'PRODUCTS';
export const PRODUCT = 'PRODUCT';
export const PRODUCT_DELETE = 'PRODUCT_DELETE';
export const PRODUCT_STATUS_UPDATE = 'PRODUCT_STATUS_UPDATE';

export const SECTIONS = 'SECTIONS';
export const SECTION = 'SECTION';
export const SECTION_DELETE = 'SECTION_DELETE';
export const SECTION_STATUS_UPDATE = 'SECTION_STATUS_UPDATE';

export const TABS = 'TABS';
export const TAB = 'TAB';
export const TAB_DELETE = 'TAB_DELETE';
export const TAB_STATUS_UPDATE = 'TAB_STATUS_UPDATE';

export const SUB_TABS = 'SUB_TABS';
export const SUB_TAB = 'SUB_TAB';
export const SUB_TAB_DELETE = 'SUB_TAB_DELETE';

export const TAB_ATTRIBUTE = 'TAB_ATTRIBUTE';
export const TAB_ATTRIBUTE_DELETE = 'TAB_ATTRIBUTE_DELETE';
export const TAB_ATTRIBUTE_STATUS_UPDATE = 'TAB_ATTRIBUTE_STATUS_UPDATE';

export const TAB_ATTRIBUTE_VALUES = 'TAB_ATTRIBUTE_VALUES';
export const TAB_ATTRIBUTE_VALUES_DELETE = 'TAB_ATTRIBUTE_VALUES_DELETE';
export const TAB_ATTRIBUTE_VALUES_STATUS_UPDATE = 'TAB_ATTRIBUTE_VALUES_STATUS_UPDATE';

export const TAB_ATTRIBUTE_VALUES_LIST = 'TAB_ATTRIBUTE_LIST_VALUES';
export const TAB_ATTRIBUTE_VALUES_LIST_DELETE = 'TAB_ATTRIBUTE_VALUES_LIST_DELETE';
export const TAB_ATTRIBUTE_VALUES_LIST_STATUS_UPDATE = 'TAB_ATTRIBUTE_VALUES_LIST_STATUS_UPDATE';

export const OPTIONS = 'OPTIONS';
export const OPTION = 'OPTION';
export const OPTION_DELETE = 'OPTION_DELETE';
export const OPTION_STATUS_UPDATE = 'OPTION_STATUS_UPDATE';

export const ATTRIBUTES = 'ATTRIBUTES';
export const ATTRIBUTE = 'ATTRIBUTE';
export const ATTRIBUTE_DELETE = 'ATTRIBUTE_DELETE';
export const ATTRIBUTE_STATUS_UPDATE = 'ATTRIBUTE_STATUS_UPDATE';

export const ATTRIBUTE_VALUES = 'ATTRIBUTE_VALUES';
export const ATTRIBUTE_VALUES_DELETE = 'ATTRIBUTE_VALUES_DELETE';
export const DUPLICATE_ATTRIBUTE = 'DUPLICATE_ATTRIBUTE';

export const ATTRIBUTE_CLIPART_VALUES = 'ATTRIBUTE_CLIPART_VALUES';
export const ATTRIBUTE_UPLOAD_IMAGE_VALUES = 'ATTRIBUTE_UPLOAD_IMAGE_VALUES';

export const ATTRIBUTE_VALUES_IMAGES = 'ATTRIBUTE_VALUES_IMAGES';
export const ATTRIBUTE_VALUES_IMAGES_DELETE = 'ATTRIBUTE_VALUES_IMAGES_DELETE';

export const TEXT_VALUES = 'TEXT_VALUES';
export const TEXT_VALUES_DELETE = 'TEXT_VALUES_DELETE';


export const CLIPARTS = 'CLIPARTS';
export const CLIPART = 'CLIPART';
export const CLIPART_DELETE = 'CLIPART_DELETE';
export const CLIPART_STATUS_UPDATE = 'CLIPART_STATUS_UPDATE';
export const CLIPART_VALUES = 'CLIPART_VALUES';
export const CLIPART_VALUES_DELETE = 'CLIPART_VALUES_DELETE';

export const MODELS = 'MODELS';
export const MODEL_VALUES = 'MODEL_VALUES';
export const LINKED_MODELS = 'LINKED_MODELS';
export const MODEL = 'MODEL';
export const MODEL_DELETE = 'MODEL_DELETE';
export const MODEL_STATUS_UPDATE = 'MODEL_STATUS_UPDATE';

export const FONTS = 'FONTS';
export const FONT = 'FONT';
export const FONT_DELETE = 'FONT_DELETE';
export const FONT_STATUS_UPDATE = 'FONT_STATUS_UPDATE';

export const FONT_COLORS = 'FONTCOLORS';
export const FONT_COLOR = 'FONTCOLOR';
export const FONT_COLOR_DELETE = 'FONT_COLOR_DELETE';
export const FONT_COLOR_STATUS_UPDATE = 'FONT_COLOR_STATUS_UPDATE';

export const FONT_SIZES = 'FONTSIZES';
export const FONT_SIZE = 'FONTSIZE';
export const FONT_SIZE_DELETE = 'FONT_SIZE_DELETE';
export const FONT_SIZE_STATUS_UPDATE = 'FONT_SIZE_STATUS_UPDATE';
export const FONT_SIZE_UNIQUE = 'FONT_SIZE_UNIQUE';

export const FONT_ALIGNS = 'FONT_ALIGNS';
export const FONT_ALIGN = 'FONT_ALIGN';
export const FONT_ALIGN_DELETE = 'FONT_ALIGN_DELETE';
export const FONT_ALIGN_STATUS_UPDATE = 'FONT_ALIGN_STATUS_UPDATE';

export const FONT_SHAPES = 'FONT_SHAPES';
export const FONT_SHAPE = 'FONT_SHAPE';
export const FONT_SHAPE_DELETE = 'FONT_SHAPE_DELETE';
export const FONT_SHAPE_STATUS_UPDATE = 'FONT_SHAPE_STATUS_UPDATE';

export const ORDERS = 'ORDERS';
export const ORDER = 'ORDER';

export const GRID = 'GRID';
export const IMAGE_UPLOAD = 'IMAGE_UPLOAD';
export const OVERLAY_IMAGE_UPLOAD = 'OVERLAY_IMAGE_UPLOAD';
export const TAB_THUMBNAIL_IMAGE = 'TAB_THUMBNAIL_IMAGE';
export const SECTION_ICON = 'SECTION_ICON';
export const FILE_UPLOAD = 'FILE_UPLOAD';

export const SETTINGS = 'SETTINGS';
export const DOMAIN_SETTINGS = 'DOMAIN_SETTINGS';
export const CONDITIONS = 'CONDITIONS';
export const CONDITION = 'CONDITION';
export const CONDITION_DELETE = 'CONDITION_DELETE';
export const ATTRIBUTE_SETTINGS = 'ATTRIBUTE_SETTINGS';

export const ACTIONS = 'ACTIONS';
export const ACTION = 'ACTION';
export const ACTION_DELETE = 'ACTION_DELETE';

export const RULES = 'RULES';
export const RULE = 'RULE';
export const RULE_DELETE = 'RULE_DELETE';

export const IMAGE_FILES = ['jpg', 'jpeg', 'png'];
export const IMAGE_FILE_SIZE = 10000000 //10MB
export const FONT_FILE = ['x-font-ttf'];
export const FILE_SIZE_ERROR = "File Size must be less than 10 MB";
// bmp, ppm, psd, pdf, pgm, gif,png,jpeg,jpg,tiff,eps,ai

export const PRINTING = 'PRINTING';
export const PRINTING_DELETE = 'PRINTING_DELETE';
export const PRINTING_STATUS_UPDATE = 'PRINTING_STATUS_UPDATE';
export const DOWNLOAD = 'DOWNLOAD';
export const PRODUCT_PRINT_SETTINGS = 'PRODUCT_PRINT_SETTINGS';
export const IMAGE_PRINT_FILES = ['jpg', 'jpeg', 'png', 'svg+xml'];
export const SUB_TAB_OPTION = 'SUB_TAB_OPTION';
export const SUB_TAB_ATTR = 'SUB_TAB_ATTR';
export const PRODUCT_TAB_ATTRIBUTE = 'PRODUCT_TAB_ATTRIBUTE';
export const READIMPORTDATA = 'READIMPORTDATA';
export const MOVEIMPORTFILES = 'MOVEIMPORTFILES';
export const IMPORTATTRIBUTE = 'IMPORTATTRIBUTE';
export const IMPORTATTRIBUTEVALUE = 'IMPORTATTRIBUTEVALUE';
export const READEXPORTDATA = 'READEXPORTDATA';
export const READEXPORTCOUNT = 'READEXPORTCOUNT';
export const READEXPORTATTRVALUEDATA = 'READEXPORTATTRVALUEDATA';
export const READEXPORTATTRVALUECOUNT = 'READEXPORTATTRVALUECOUNT';
export const REMOVEEXPORTFILE = 'REMOVEEXPORTFILE';
export const READIMPORTTHUMBDATA = 'READIMPORTTHUMBDATA';
export const MOVEIMPORTTHUMBFILES = 'MOVEIMPORTTHUMBFILES';