import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/logo.png'
class Sidebar extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        let location = window.location.href;
        let plan = localStorage.getItem('plan_id');
        return (
            <div className="promize-side-container">
                <nav className="promize-sidebar-nav">
                    <div className="promize-logo">
                        <NavLink to="/">
                            <img src={logo} />
                        </NavLink>
                    </div>
                    <ul className="promize-menu-items">
                        {<li className={(location.indexOf('dashboard') > -1) ? "promize-sizebar-item" : "promize-sizebar-item"}>
                            <NavLink to="/dashboard" exact className="promize-sidebar-links">
                                <div>
                                    <i className="fa fa-tachometer-alt"></i>
                                    <span className="promize-menu-name">
                                        Dasboard
                                    </span>
                                </div>
                            </NavLink>
                        </li>}
                        <li className={(location.indexOf('attributes') > -1) ? "promize-sizebar-item active" : "promize-sizebar-item"} >
                            <NavLink to="/attributes" className="promize-sidebar-links">
                                <div>
                                    <i className="fa fa-table"></i>
                                    <span className="promize-menu-name">
                                        Attributes
                                    </span>
                                </div>
                            </NavLink>
                        </li>
                        <li className={(location.indexOf('products') > -1 || (location.slice(-1)) == "/") ? "promize-sizebar-item active" : "promize-sizebar-item"} >
                            <NavLink to="/products" className="promize-sidebar-links">
                                <div>
                                    <i className="fa fa-cubes"></i>
                                    <span className="promize-menu-name">
                                        Products
                                    </span>
                                </div>
                            </NavLink>
                        </li>
                        {/* <li className={(location.indexOf('products') > -1) ? "promize-sizebar-item active" : "promize-sizebar-item"} >
                            <NavLink to="/3Dproducts" className="promize-sidebar-links">
                            <div>
                                <i className="fa fa-cubes"></i>
                                    <span className="promize-menu-name">
                                        3D Products
                                    </span>
                                </div>
                            </NavLink>
                        </li> */}

                        <li className={(location.indexOf('orders') > -1) ? "promize-sizebar-item active" : "promize-sizebar-item"} >
                            <NavLink to="/orders" className="promize-sidebar-links">
                                <div>
                                    <i className="fa fa-store"></i>
                                    <span className="promize-menu-name">
                                        Orders
                                    </span>
                                </div>
                            </NavLink>
                        </li>
                        {plan == 3 && <li className={(location.indexOf('models') > -1) ? "promize-sizebar-item active" : "promize-sizebar-item"} >
                            <NavLink to="/models" className="promize-sidebar-links">
                                <div>
                                    <i className="fab fa-unity"></i>
                                    <span className="promize-menu-name">
                                        3D Models
                                    </span>
                                </div>
                            </NavLink>
                        </li>}
                        <li className={(location.indexOf('settings') > -1) ? "promize-sizebar-item active" : "promize-sizebar-item"} >
                            <NavLink to="/settings" className="promize-sidebar-links">
                                <div>
                                    <i className="fa fa-puzzle-piece"></i>
                                    <span className="promize-menu-name">
                                        Settings
                                    </span>
                                </div>
                            </NavLink>
                        </li>
                        <li className={(location.indexOf('userguide') > -1) ? "promize-sizebar-item active" : "promize-sizebar-item"} >
                            <NavLink to="/userguide" className="promize-sidebar-links">
                                <div>
                                    <i className="fa fa-map-signs"></i>
                                    <span className="promize-menu-name">
                                        User Guide
                                    </span>
                                </div>
                            </NavLink>
                        </li>
                        {/* <li className={(location.indexOf('logout') > -1) ? "promize-sizebar-item active" : "promize-sizebar-item"} >
                            <NavLink to="/logout" className="promize-sidebar-links">
                            <div>
                            <i className="fa fa-sign-out-alt"></i>
                                    <span className="promize-menu-name">
                                        Logout
                                    </span>
                                </div>
                            </NavLink>
                        </li> */}
                    </ul>
                </nav>
            </div>
        )
    }
}

export default connect()(Sidebar);
